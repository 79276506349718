// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.programs {
}
.programs__block {
    display: flex;
    align-items: center;
    background-color: var(--accent-color);
    border-radius: 20px;
}
.programs__picture {
}
.programs__content {
    color: #fff;
}
.programs__content-text {
    margin-top: 20px;
    margin-bottom: 40px;
}
.programs__content-button {
    font-size: 20px;
    border: 1px solid #343434;
    padding: 15px 40px;
    color: #fff;
    background-color: #343434;
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/css/programs.css"],"names":[],"mappings":"AAAA;AACA;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,qCAAqC;IACrC,mBAAmB;AACvB;AACA;AACA;AACA;IACI,WAAW;AACf;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":[".programs {\n}\n.programs__block {\n    display: flex;\n    align-items: center;\n    background-color: var(--accent-color);\n    border-radius: 20px;\n}\n.programs__picture {\n}\n.programs__content {\n    color: #fff;\n}\n.programs__content-text {\n    margin-top: 20px;\n    margin-bottom: 40px;\n}\n.programs__content-button {\n    font-size: 20px;\n    border: 1px solid #343434;\n    padding: 15px 40px;\n    color: #fff;\n    background-color: #343434;\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
