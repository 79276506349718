// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[2]!./css/reset.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[2]!./css/menu.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[2]!./css/info.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[2]!./css/target.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[2]!./css/programs.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[2]!./css/advantages.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[2]!./css/facilities.css";
import ___CSS_LOADER_AT_RULE_IMPORT_7___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[2]!./css/versions.css";
import ___CSS_LOADER_AT_RULE_IMPORT_8___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[2]!./css/footer.css";
import ___CSS_LOADER_AT_RULE_IMPORT_9___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[2]!./css/forms.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/assets/fonts/Montserrat-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/assets/fonts/Montserrat-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_8___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
main {
    color: var(--dark-color);
    background-color: var(--light-dark-color);
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
:root {
    --accent-color: #02A9F7;
    --light-dark-color: #e9e9e9;
    --dark-color: #111111;
}
.text1 {
    font-weight: 700;
    font-size: 64px;
    line-height: 78px;
}
.text2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 59px;
    margin-top: 80px;
    margin-bottom: 40px;
}
.text3 {
    font-weight: 700;
    font-size: 24px;
}
.text {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
}
.container {
    max-width: 1150px;
    margin: 0 auto;
}
button, a {
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAWA;IACI,gBAAgB;IAChB,qCAAqC;IACrC,4CAAuD;AAC3D;AACA;IACI,gBAAgB;IAChB,qCAAqC;IACrC,4CAAoD;AACxD;AACA;IACI,wBAAwB;IACxB,yCAAyC;IACzC,qCAAqC;IACrC,gBAAgB;AACpB;AACA;IACI,uBAAuB;IACvB,2BAA2B;IAC3B,qBAAqB;AACzB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,qCAAqC;IACrC,eAAe;AACnB","sourcesContent":["@import url(./css/reset.css);\n@import url(./css/menu.css);\n@import url(./css/info.css);\n@import url(./css/target.css);\n@import url(./css/programs.css);\n@import url(./css/advantages.css);\n@import url(./css/facilities.css);\n@import url(./css/versions.css);\n@import url(./css/footer.css);\n@import url(./css/forms.css);\n\n@font-face {\n    font-weight: 400;\n    font-family: 'Montserrat', sans-serif;\n    src: url(../public/assets/fonts/Montserrat-Regular.ttf);\n}\n@font-face {\n    font-weight: 700;\n    font-family: 'Montserrat', sans-serif;\n    src: url(../public/assets/fonts/Montserrat-Bold.ttf);\n}\nmain {\n    color: var(--dark-color);\n    background-color: var(--light-dark-color);\n    font-family: 'Montserrat', sans-serif;\n    font-weight: 400;\n}\n:root {\n    --accent-color: #02A9F7;\n    --light-dark-color: #e9e9e9;\n    --dark-color: #111111;\n}\n.text1 {\n    font-weight: 700;\n    font-size: 64px;\n    line-height: 78px;\n}\n.text2 {\n    font-weight: 700;\n    font-size: 48px;\n    line-height: 59px;\n    margin-top: 80px;\n    margin-bottom: 40px;\n}\n.text3 {\n    font-weight: 700;\n    font-size: 24px;\n}\n.text {\n    font-weight: 400;\n    font-size: 18px;\n    line-height: 20px;\n}\n.container {\n    max-width: 1150px;\n    margin: 0 auto;\n}\nbutton, a {\n    font-family: 'Montserrat', sans-serif;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
