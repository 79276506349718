// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.target-consumer {
}
.target-consumer__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.target__content-2col{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.target-picture{
    width: 100%;
    padding: 0;
}
.target-consumer__block {
    width: 580px;
    padding: 40px;
    border: 1px solid #343434 transparent;
    border-radius: 20px;
    background-color: #fff;
}
.target-text3{
    margin-top: 30px;
}
.target-text {
    margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/css/target.css"],"names":[],"mappings":"AAAA;AACA;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;AACb;AACA;IACI,aAAa;IACb,eAAe;IACf,SAAS;AACb;AACA;IACI,WAAW;IACX,UAAU;AACd;AACA;IACI,YAAY;IACZ,aAAa;IACb,qCAAqC;IACrC,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".target-consumer {\n}\n.target-consumer__content {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 20px;\n}\n.target__content-2col{\n    display: flex;\n    flex-wrap: wrap;\n    gap: 20px;\n}\n.target-picture{\n    width: 100%;\n    padding: 0;\n}\n.target-consumer__block {\n    width: 580px;\n    padding: 40px;\n    border: 1px solid #343434 transparent;\n    border-radius: 20px;\n    background-color: #fff;\n}\n.target-text3{\n    margin-top: 30px;\n}\n.target-text {\n    margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
