import './App.css';

function App() {
  return (
    <main>
         <header>
      <div className="container">
        <div className="container-mini">
          <div className="menu">
            <div>
              <a className="menu__logo" href="/">
                  <img src="resourses/logo.svg" alt="logo"/>
                  <p>Soft V</p>
              </a>
            </div>
            <nav className="menu__nav">
              <ul className="nav__list tag">
                <li>
                  <a className="nav__link heading-text" href="#target-consumer">Для кого?</a>
                </li>
                <li>
                  <a className="nav__link heading-text" href="#projects">Программы</a>
                </li>
                <li>
                  <a className="nav__link heading-text" href="#services">Преимущества</a>
                </li>
                <li>
                  <a className="nav__link heading-text" href="#versions">Стоимость</a>
                </li>
                <li>
                  <button className="nav__link nav__link-button">Подробнее</button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
        <main>
        <section id="info" className="info">
        <div className="container">
            <div className="info-content">
                <div>
                    <h1 className="text1">Robocad V</h1>
                    <p className="info-text">
                      Позволяет обучаться, практиковаться и повышать свои навыки в робототехнике
                      <br></br>
                      По доступной цене</p>
                      <button className="promo-button"onclick="scrollToSection('versions')">Выбрать программу
                        <img src="resourses/promo-button.svg" alt=""/>
                      </button>
                </div>
                <div className="info-picture">
                    <img src="resourses/Mask group (1).png" alt=""/>
                </div>
            </div>
        </div>
      </section>
      <section id="target-consumer" className="target-consumer">
        <div className="container">
          <h2 className="text2">Для кого подходит?</h2>
        <div className="target-consumer__content">
          <div className="target-consumer__block">
            <img src="resourses/target-icon1.png" alt=""/>
            <img className="target-picture" src="resourses/target.png" alt=""/>
            <p className="text3 target-text3">Школьники</p>
            <p className="target-text">Благодаря подробному методическому комплексу сделать первые шаги в робототехнике проще простого!</p>
          </div>
          <div className="target__content-2col">
            <div className="target-consumer__block">
            <img src="resourses/target-icon2.png" alt=""/>
            <p className="text3 target-text3">Студенты СУЗов и ВУЗов</p>
            <p className="target-text">Благодаря большому количеству поддерживаемых языков программирования студенты различных направлений смогут погрузиться в изучение мобильной робототехники!</p>
          </div>
          <div className="target-consumer__block">
            <img src="resourses/target-icon3.png" alt=""/>
            <p className="text3 target-text3">Слушатели курсов квалификации</p>
            <p className="target-text">Для проведения курсов преподавателям достаточно обычного кабинета с ноутбуками или стационарными компьютерами, что позволит в том числе проводить онлайн уроки по всему миру!</p>
          </div>
          </div>
        </div>
        </div>
      </section>
      <section id="projects" className="programs">
        <div className="container">
          <h2 className="text2">Программы</h2>
          <div className="programs__block">
            <div className="programs__picture">
              <img src="resourses/programs.png" alt=""/>
            </div>
            <div className="programs__content">
              <p className="text3">Все гениальное — Просто</p>
              <p className="text programs__content-text">Наш фирменный «Установщик» — сам загрузит и установит выбранный вами программный продукт. Автоматически установит нужные библиотеки и все настроит.</p>
              <a href="https://softv.su/resources/Apps/SoftHub/installers/win/softhub_x64.exe" download>
              <button className="programs__content-button">Скачать SoftHub <img src="resourses/promo-button.svg" alt=""/></button>
            </a>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="advantages">
        <div className="container">
          <h2 className="text2">Преимущества Robocad V</h2>
          <div className="advantages__blocks">
            <img src="resourses/adv1.svg" alt=""/>
            <img src="resourses/adv2.svg" alt=""/>
            <img src="resourses/adv3.svg" alt=""/>
          </div>
            </div>
          </section>
          <section className="facilities">
        <div className="container">
          <h2 className="text2">Возможности</h2>
          <p className="facilities-text">Все типы лицензий поддерживают следующий функционал:</p>
          <img src="resourses/facilities.png" alt=""/>
        </div>
      </section>
      <section id="versions" className="versions">
        <div className="container">
            <h2 className="text2">Ключевые различия версий</h2>
            <div className="versions__blocks">
                <div className="block block_one">
                    <p className="version_name">Локальный</p>
                    <p className="version_price">По запросу</p>
                    <a href="https://t.me/SoftVsupport_bot" target="_blank" rel="noopener noreferrer"></a>
                    <button className="version_design" >Оформить версию</button>
                    <div className="version__options">
                        <div className="version_option">
                            <img src="resourses/vers_i1.png" alt=""/>
                            <p>Методический комплекс с уроками и практическими работами на 144ч.</p>
                        </div>
                        <div className="version_option">
                            <img src="resourses/vers_i2.png" alt=""/>
                            <p>Добавление новых конкурсных полей и роботов российского рынка</p>
                        </div>
                        <div className="version_option">
                            <img src="resourses/vers_i3.png" alt=""/>
                            <p>Цифровой двойник (загрузка программного кода из симулятора в реального робота за 2 клика)</p>
                        </div>
                    </div>
                </div>
                <div className="economy_panel">
                    <p className="economy_panel-big"> &gt; 10 раз </p>
                    <p className="economy_panel-little">экономия возрастает на 1 рабочее место</p>
                </div>
                <div className="license-banner">
                  <div className="license-banner-left">
                    <p>Выберите лицензию, подходящую вам!</p>
                    <a href="https://t.me/SoftVsupport_bot" target="_blank" rel="noopener noreferrer">
                    <button className="license-banner-button">DEMO (Telegram)   
                      <img src="resourses/promo-button.svg" alt=""/>
                    </button>
                  </a>
                  </div>
                  <div className="license-banner-right">
                    <img src="resourses/block-versions.png" alt="arrow icon"/>
                  </div>
                </div>
                <div className="block block_two">
                    <p className="version_name">Универсальный</p>
                    <p className="version_price">По запросу</p>
                    <a href="https://t.me/SoftVsupport_bot" target="_blank" rel="noopener noreferrer"></a>
                    <button className="version_design" >Оформить версию</button>
                    <div className="version__options">
                        <div className="version_option">
                            <img src="resourses/vers_i1.png" alt=""/>
                            <p>Методический комплекс с уроками и практическими работами на 144ч.</p>
                        </div>
                        <div className="version_option">
                            <img src="resourses/vers_i2.png" alt=""/>
                            <p>Добавление новых конкурсных полей и роботов российского рынка</p>
                        </div>
                        <div className="version_option">
                            <img src="resourses/vers_i3.png" alt=""/>
                            <p>Цифровой двойник (загрузка программного кода из симулятора в реального робота за 2 клика)</p>
                        </div>
                        <div className="version_option">
                            <img src="resourses/vers_i4.png" alt=""/>
                            <p>Аккаунт с функцией управления лицензиями, ключи отражают количество рабочих мест</p>
                        </div>
                        <div className="version_option">
                            <img src="resourses/vers_i5.png" alt=""/>
                            <p>Встроенный конструктор собственных моделей роботов</p>
                        </div>
                        <div className="version_option">
                            <img src="resourses/vers_i6.png" alt=""/>
                            <p>Дистанционный доступ к лицензии</p>
                        </div>
                    </div>
                </div>
                <div className="block block_three">
                    <p className="version_name">Максимальный</p>
                    <p className="version_price">По запросу</p>
                    <a href="https://t.me/SoftVsupport_bot" target="_blank" rel="noopener noreferrer"></a>
                    <button className="version_design" onclick="scrollToSection('forms')">Оформить версию</button>
                    <div className="version__options">
                        <div className="version_option">
                            <img src="resourses/vers_i1.png" alt=""/>
                            <p>Методический комплекс с уроками и практическими работами на 144ч.</p>
                        </div>
                        <div className="version_option">
                            <img src="resourses/vers_i2.png" alt=""/>
                            <p>Добавление новых конкурсных полей и роботов российского рынка</p>
                        </div>
                        <div className="version_option">
                            <img src="resourses/vers_i3.png" alt=""/>
                            <p>Цифровой двойник (загрузка программного кода из симулятора в реального робота за 2 клика)</p>
                        </div>
                        <div className="version_option">
                            <img src="resourses/vers_i4.png" alt=""/>
                            <p>Аккаунт с функцией управления лицензиями, ключи отражают количество рабочих мест</p>
                        </div>
                        <div className="version_option">
                            <img src="resourses/vers_i5.png" alt=""/>
                            <p>Встроенный конструктор собственных моделей роботов</p>
                        </div>
                        <div className="version_option">
                            <img src="resourses/vers_i6.png" alt=""/>
                            <p>Дистанционный доступ к лицензии</p>
                        </div>
                        <div className="version_option">
                            <img src="resourses/vers_i1.png" alt=""/>
                            <p>Методический комплекс с уроками и практическими работами на 256ч.</p>
                        </div>
                        <div className="version_option">
                            <img src="resourses/vers_i8.png" alt=""/>
                            <p>Курс уникальных упражнений от тренерского состава сборной России по робототехнике</p>
                        </div>
                        <div className="version_option">
                            <img src="resourses/vers_i9.png" alt=""/>
                            <p>Симуляция реальных кейсов в промышленных условиях</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="forms" className="forms">
      <div className="container"> 
          <h2 className="form-title">Оставьте нам заявку</h2>
          <p className="form-subtitle">Свяжемся с вами в течении рабочего дня</p>
          <form className="contact-form">
              <div className="form-group">
                  <input type="text" placeholder="Ваше имя" required/>
                  <input type="email" placeholder="Ваш эл. адрес" required/>
                  <input type="text" placeholder="Тема сообщения" required/>
              </div>
              <div className="form-group">
                  <textarea placeholder="Ваше сообщение" required></textarea>
              </div>  
          </form>
          <div className="form-footer">
                  <p>Нажимая на кнопку, я соглашаюсь на обработку <a href="#">персональных данных</a> и с <a href="#">правилами пользования Платформой</a></p>
                  <button type="submit">Отправить</button>
              </div>
      </div>
  </section>

  <section className="application"></section>
    <footer>
      <div className="footer-container">
        <div className="container">
          <div className="footer-content">
            <div className="footer-column">
              
              <img src="resourses/logo.svg" alt="logo"/>
          </div>
          <div className="footer-column">
               
              <ul>
                  <li><a href="#">Главная</a></li>
                  <li><a href="#target-consumer">Для кого</a></li>
                  <li><a href="#forms">Обратная связь</a></li>
              </ul>
          </div>
          <div className="footer-column">
               
              <ul>
                  <li><a href="#versions">Стоимость</a></li>
                  <li><a href="#versions">Локальный</a></li>
                  <li><a href="#versions">Универсальный</a></li>
                  <li><a href="#versions">Максимальный</a></li>
              </ul>
          </div>
          <div className="footer-column">
               
              <p>+7 (993) 402-04-92</p>
              <p>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «СОФТВЕРЫ»</p>
              <p>420075 Республика Татарстан, г. Казань, ул Азина, д. 21,</p>
              <p>ООО «СОФТВЕРЫ»<br/>ИНН 1686007658</p>
          </div>
          <div className="footer-column">
               
              <p><a href="mailto:softvery@yandex.ru">softvery@yandex.ru</a></p>
               <p>&copy; 2023 «СОФТВЕРЫ»<br/><a href="#">Политика конфиденциальности</a></p>
          </div>
          </div>
          
    </div>
        </div>
  </footer>
        </main>
    </main>
  );
}
function scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth'
        });
      }
    }

    // Обработка кликов на ссылках в меню
    document.querySelectorAll('.nav__link').forEach(link => {
      link.addEventListener('click', function(event) {
        event.preventDefault();
        const targetId = this.getAttribute('href').substring(1);
        scrollToSection(targetId);
      });
    });
export default App;
