// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info {
    margin: 0 30px;
    background-color: var(--accent-color);
    border-radius: 20px;
    object-fit: cover;
}
.info-content {
    padding: 86px 80px;
    color: #fff;
    font-weight: 400;
    font-size: 32px;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
}
.info-text {
    margin-top: 20px;
    margin-bottom: 40px;
}
.info-picture img {
    width: 550px;
    object-fit: cover;
}
.promo-button {
    font-size: 20px;
    border: 1px solid #343434;
    padding: 15px 40px;
    color: #fff;
    background-color: #343434;
    border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/css/info.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,qCAAqC;IACrC,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,8BAA8B;IAC9B,SAAS;AACb;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":[".info {\n    margin: 0 30px;\n    background-color: var(--accent-color);\n    border-radius: 20px;\n    object-fit: cover;\n}\n.info-content {\n    padding: 86px 80px;\n    color: #fff;\n    font-weight: 400;\n    font-size: 32px;\n    margin-top: 30px;\n    margin-bottom: 30px;\n    display: flex;\n    justify-content: space-between;\n    gap: 50px;\n}\n.info-text {\n    margin-top: 20px;\n    margin-bottom: 40px;\n}\n.info-picture img {\n    width: 550px;\n    object-fit: cover;\n}\n.promo-button {\n    font-size: 20px;\n    border: 1px solid #343434;\n    padding: 15px 40px;\n    color: #fff;\n    background-color: #343434;\n    border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
