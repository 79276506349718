// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
    background-color: #111111;
    
}
.footer-content {
    display: flex;
    justify-content: space-between;
    padding: 30px;
}

.footer-column {
    flex: 1 1;
}

.footer-column p {
    margin: 0px;
    margin-bottom: 10px;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
}

.footer-column ul {
    padding: 0;
    list-style-type: none;
}

.footer-column ul li {
    margin-bottom: 20px;
}

.footer-column ul li a{
    color: #fff;
    text-decoration: none;
    font-size: 16px;
}

.footer-column ul li a:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/css/footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;;AAE7B;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".footer-container {\r\n    background-color: #111111;\r\n    \r\n}\r\n.footer-content {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    padding: 30px;\r\n}\r\n\r\n.footer-column {\r\n    flex: 1;\r\n}\r\n\r\n.footer-column p {\r\n    margin: 0px;\r\n    margin-bottom: 10px;\r\n    color: #fff;\r\n    font-size: 12px;\r\n    text-transform: uppercase;\r\n}\r\n\r\n.footer-column ul {\r\n    padding: 0;\r\n    list-style-type: none;\r\n}\r\n\r\n.footer-column ul li {\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.footer-column ul li a{\r\n    color: #fff;\r\n    text-decoration: none;\r\n    font-size: 16px;\r\n}\r\n\r\n.footer-column ul li a:hover {\r\n    text-decoration: underline;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
