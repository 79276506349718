// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
    background-color: #fff;
    color: var(--dark-color);
}
.container-mini {
    max-width: 960px;
    margin: 0 auto;
}
.menu {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.menu__logo {
    display: flex;
    align-items: center;
    gap: 20px;
    color: var(--dark-color);
    text-decoration: none;
}
.menu__logo img {
    width: 65px;
    height: 65px;
    object-fit: cover;
}
.menu__logo p {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 28px;
}
.menu__nav {
    
}
.nav__list {
    display: flex;
    align-items: center;
    gap: 20px;
}
.nav__link {
    text-decoration: none;
    color: var(--dark-color);
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
}
.nav__link-button {
    border: 2px solid var(--accent-color);
    border-radius: 10px;
    padding: 10px 20px;
    background-color: #fff;
    color: var(--accent-color);
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/css/menu.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,wBAAwB;AAC5B;AACA;IACI,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,wBAAwB;IACxB,qBAAqB;AACzB;AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;AACnB;AACA;;AAEA;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,qBAAqB;IACrB,wBAAwB;IACxB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,qCAAqC;IACrC,mBAAmB;IACnB,kBAAkB;IAClB,sBAAsB;IACtB,0BAA0B;IAC1B,eAAe;AACnB","sourcesContent":["header {\n    background-color: #fff;\n    color: var(--dark-color);\n}\n.container-mini {\n    max-width: 960px;\n    margin: 0 auto;\n}\n.menu {\n    padding-top: 10px;\n    padding-bottom: 10px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n.menu__logo {\n    display: flex;\n    align-items: center;\n    gap: 20px;\n    color: var(--dark-color);\n    text-decoration: none;\n}\n.menu__logo img {\n    width: 65px;\n    height: 65px;\n    object-fit: cover;\n}\n.menu__logo p {\n    text-transform: uppercase;\n    font-weight: 700;\n    font-size: 28px;\n}\n.menu__nav {\n    \n}\n.nav__list {\n    display: flex;\n    align-items: center;\n    gap: 20px;\n}\n.nav__link {\n    text-decoration: none;\n    color: var(--dark-color);\n    font-weight: 400;\n    font-size: 20px;\n    line-height: 20px;\n}\n.nav__link-button {\n    border: 2px solid var(--accent-color);\n    border-radius: 10px;\n    padding: 10px 20px;\n    background-color: #fff;\n    color: var(--accent-color);\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
